<template>
  <div class="contentWrapper">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
    </v-overlay>
    <template>
      <p>Kod za verifikaciju je poslat na Vašu email adresu. Vreme trajanja koda je 10 minuta.</p>
      <div>
        <div>
          <v-otp-input type="number" v-model.number="otp" @keypress="isNumber($event)" color="#137547"></v-otp-input>
        </div>
      </div>

      <p class="codeError">{{ codeError }}</p>
      <p class="sendAgain">Nije Vam stigao kod?<button class="button" @click="sendAgain" :disabled="this.disabledSendAgain">Pošalji ponovo</button></p>

      <v-card-actions class="buttonsWrapper">
        <button class="goBackButton" @click="goBack">Nazad</button>
        <button class="goNextButton" @click="submitData">Sledeći korak</button>
      </v-card-actions>
    </template>
    <v-snackbar v-model="snackbar" color="green" :timeout="2000">Kod je poslat. Proverite e-mail.</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Verification",
  data() {
    return {
      snackbar: false,
      loading: false,
      disabledSendAgain: false,
      otp: "",
      codeError: "",
    };
  },
  methods: {
    async sendAgain() {
      this.disabledSendAgain = true;
      await axios
        .post("Register", this.newUserData)
        .then((response) => {
          this.loading = false;
          this.disabledSendAgain = false;
          if (response.status === 200) {
            this.snackbar = true;
            setTimeout(3000, () => (this.snackbar = false));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.disabledSendAgain = false;
          if (error.response.data == "Email is already taken.") {
            this.emailError = true;
          } else if (error.response.data == "Username is already taken.") {
            this.usernameError = true;
          }
        });
    },
    async submitData() {
      this.loading = true;
      let code = {
        validationKey: this.otp.toString(),
      };
      await axios
        .post("Register/Validate", code)
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.$emit("verifCompleted");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.otp = "";
          if (error.response.data == "Wrong Key.") {
            this.codeError = "Uneli ste pogrešan kod.";
          } else if (error.response.data == "Key Expired.") {
            this.codeError = "Kod je istekao. Posaljite zahtev ponovo.";
          }
        });
    },
    goBack() {
      this.$emit("goToPrevious");
    },
  },
  computed: {
    newUserData() {
      return this.$store.getters.StateNewUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sendAgain {
    text-align: center;
    .button {
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;
      color: #137547;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #137547;
    }
  }
  .goBackButton {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    color: #137547;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #137547;
  }
  .goNextButton {
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #137547;
    border-radius: 4px;
  }
}

.codeError {
  color: red;
  text-align: center;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

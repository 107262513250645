<template>
  <div>
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-text-field
          @keypress="isLetter($event)"
          color="#137547"
          outlined
          label="Ime"
          placeholder="Unesite ime"
          class="vtxt"
          type="text"
          autofocus
          :rules="nameRules"
          v-model.trim="user.firstName"
        ></v-text-field>
        <v-text-field
          @keypress="isLetter($event)"
          color="#137547"
          outlined
          :rules="lastNameRules"
          label="Prezime"
          placeholder="Unesite prezime"
          class="vtxt"
          type="text"
          v-model.trim="user.lastName"
        ></v-text-field>
        <v-text-field
          @keypress="isLetter($event)"
          color="#137547"
          outlined
          :rules="locationRules"
          label="Lokacija"
          placeholder="Unesite lokaciju"
          class="vtxt"
          type="text"
          v-model.trim="user.location"
        ></v-text-field>
        <v-text-field
          color="#137547"
          outlined
          :rules="phoneNumberRules"
          label="Broj telefona"
          placeholder="Unesite broj telefona"
          class="vtxt"
          type="number"
          v-model.trim="user.phoneNumber"
        ></v-text-field>
        <v-text-field
          @keydown.space.prevent
          color="#137547"
          outlined
          :rules="userNameRules"
          :error="usernameError"
          @click="() => (this.usernameError = false)"
          label="Korisničko ime"
          placeholder="Unesite korisničko ime"
          class="vtxt"
          type="text"
          v-model.trim="user.userName"
        ></v-text-field>
        <div v-if="usernameError" style="margin-top: -1.5rem">
          <p style="text-align: center; color: red">Korisnicko ime je vec zauzeto</p>
        </div>
        <v-text-field
          @keydown.space.prevent
          color="#137547"
          outlined
          :rules="emailRules"
          :error="emailError"
          @click="() => (this.emailError = false)"
          label="Email"
          placeholder="Unesite email"
          class="vtxt"
          type="email"
          v-model="user.email"
        ></v-text-field>
        <div v-if="emailError" style="margin-top: -1.5rem">
          <p style="text-align: center; color: red">Korisnik sa ovim emailom vec postoji</p>
        </div>
        <v-text-field
          v-model="user.password"
          color="#137547"
          outlined
          label="Lozinka"
          placeholder="Unesite lozinku"
          class="vtxt"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :rules="passwordRules"
          required
        ></v-text-field>
        <v-text-field
          v-model="user.repeatedPassword"
          color="#137547"
          outlined
          label="Ponovite lozinku"
          placeholder="Ponovo unesite lozinku"
          class="vtxt"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :rules="repeatedPasswordRules"
          required
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="nextStepButton">
      <v-btn color="#137547" @click="submitForm">
        <p style="margin: auto; color: #ffff">SLEDEĆI KORAK</p>
      </v-btn>
    </v-card-actions>
    <div class="alreadyHaveAcc">
      <p>Već imaš nalog?</p>
      <p @click="() => this.$router.push('/prijava')" class="signInText">Prijavi se</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "BasicInfo",
  data() {
    return {
      loading: false,
      showPassword: false,
      usernameError: false,
      emailError: false,
      user: {
        firstName: "",
        lastName: "",
        location: "",
        phoneNumber: "",
        userName: "",
        email: "",
        password: "",
        repeatedPassword: "",
      },
      nameRules: [(v) => !!v || "Ime je obavezno.", (v) => (v && v.length <= 15) || "Ime ne može da ima više od 15 karaktera."],
      lastNameRules: [(v) => !!v || "Prezime je obavezno.", (v) => (v && v.length <= 15) || "Prezime ne može da ima više od 15 karaktera."],
      locationRules: [(v) => !!v || "Lokacija je obavezna."],
      phoneNumberRules: [(v) => !!v || "Broj telefona je obavezan"],
      userNameRules: [(v) => !!v || "Korisničko ime je obavezno.", (v) => (v && v.length <= 15) || "Korisničko ime ne može da ima više od 15 karaktera."],
      emailRules: [
        (v) => !!v || "Email je obavezan.",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "Unesite email u ispravnom formatu.",
      ],
      passwordRules: [(v) => !!v || "Lozinka je obavezna.", (v) => (v && v.length >= 6) || "Lozinka ne sme da ima manje od 7 karaktera."],
      repeatedPasswordRules: [(v) => !!v || "Lozinka je obavezna.", (v) => v === this.user.password || "Lozinke se ne poklapaju."],
    };
  },
  methods: {
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (isNaN(char) || char == " ") return true;
      else e.preventDefault();
    },
    ...mapActions(["AddNewUserInfo"]),
    async saveDataInVuex() {
      await this.AddNewUserInfo(this.user).then(() => {
        this.$emit("basicInfoEntered");
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await axios
          .post("Register", this.user)
          .then((response) => {
            this.loading = false;
            if (response.status === 200) {
              this.saveDataInVuex();
            }
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data == "Email is already taken.") {
              this.emailError = true;
            } else if (error.response.data == "Username is already taken.") {
              this.usernameError = true;
            }
          });
      }
    },
  },
  computed: {
    newUserData() {
      return this.$store.getters.StateNewUser;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.user.firstName = this.newUserData.firstName;
      this.user.lastName = this.newUserData.lastName;
      this.user.location = this.newUserData.location;
      this.user.phoneNumber = this.newUserData.phoneNumber;
      this.user.userName = this.newUserData.userName;
      this.user.email = this.newUserData.email;
      this.user.password = this.newUserData.password;
    });
  },
};
</script>

<style lang="scss" scoped>
.nextStepButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alreadyHaveAcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem;
}

.signInText {
  margin-left: 5px;
  color: #137547;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
}
</style>

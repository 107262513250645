<template>
  <div>
    <NavBar />
    <div class="dialogWrapper">
      <div class="dialogWindow">
        <v-card-title class="tabWrapper">
          <div class="activeTab"><p>Podaci</p></div>
          <div :class="currentTab >= 2 ? 'activeTab' : 'tab'">
            <p>Verifikacija</p>
          </div>
          <div :class="currentTab >= 3 ? 'activeTab' : 'tab'">
            <p>Potvrda</p>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="currentTab === 1">
          <BasicInfo v-model="currentTab" @basicInfoEntered="goToSecondTab" />
        </div>
        <v-card-text v-if="currentTab === 2">
          <Verification v-model="currentTab" @goToPrevious="goToFirstTab" @verifCompleted="goToThirdTab" />
        </v-card-text>
        <v-card-text v-if="currentTab === 3">
          <RegistrationFinished v-model="currentTab" />
        </v-card-text>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import BasicInfo from "./BasicInfo.vue";
import Verification from "./Verification.vue";
import RegistrationFinished from "./RegistrationFinished.vue";
import NavBar from "../../Components/NavBar.vue";
import Footer from "../../Components/Footer.vue";

export default {
  name: "SignUp",
  data() {
    return {
      currentTab: 1,
    };
  },
  methods: {
    goToFirstTab() {
      this.currentTab = 1;
    },
    goToSecondTab() {
      this.currentTab = 2;
    },
    goToThirdTab() {
      this.currentTab = 3;
    },
  },
  components: {
    BasicInfo,
    Verification,
    RegistrationFinished,
    NavBar,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  margin: 5rem 1rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogWindow {
  width: 600px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
}

.tab {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.activeTab {
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #ffff;
  background-color: #137547;
  border-bottom: 1px solid #ccc;
}

.activeTab:first-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #137547;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.activeTab:last-child {
  flex: 33.33%;
  text-align: center;
  color: #ffff;
  background-color: #137547;
}

.activeTab p,
.tab p {
  margin: 0;
  font-size: 1rem;
  word-break: keep-all;
}

.v-card__subtitle,
.v-card__title {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .mainDiv {
    width: 30%;
  }

  .dialogWindow {
    margin: 0 auto;
  }

  .tabWrapper {
    flex-direction: row;
  }

  .tab {
    width: 33%;
    border-bottom: 1px solid grey;
  }
  .activeTab {
    width: 33%;
  }
  .activeTab:first-child {
    border-top-right-radius: 0px;
  }
  .activeTab:last-child {
    border-top-right-radius: 4px;
  }
}
</style>
